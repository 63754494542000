import { useQueryClient } from '@tanstack/react-query'
import React, { useCallback, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import useRole from '../../../../hooks/useRole'
import {
  useDeleteTemplate,
  useTemplates,
  useUpsertTemplate,
} from '../../../../hooks/useTemplate/useTemplateBase'
import {
  LibraryItemType,
  Template,
  TYPE_TRANSLATIONS,
} from '../../../../types/domain'
import Button from '../../../core/Button/Button'
import ConfirmationModal from '../../../core/ConfirmationModal/ConfirmationModal'
import { SearchInput } from '../../../core/SearchInput/SearchInput'
import useLibraryStore from '../store'
import { VALIDATION_ERROR } from '../types'
import { TemplateCard } from './TemplateCard'
import TemplateManagementModal from './TemplateManagementModal'

const TemplateManagement: React.FC = () => {
  const { t } = useTranslation()
  const { isManager } = useRole()
  const queryClient = useQueryClient()

  const {
    templateModal,
    openTemplateModal,
    closeTemplateModal,
    deleteModal,
    openDeleteModal,
    closeDeleteModal,
    showToast,
    setIsSaving,
  } = useLibraryStore()

  const { data: templates = [], isLoading } = useTemplates()
  const { mutateAsync: upsertTemplate } = useUpsertTemplate()
  const { mutateAsync: deleteTemplate } = useDeleteTemplate()

  const [searchQuery, setSearchQuery] = useState('')

  const handleSubmit = async (data: Partial<Template>) => {
    try {
      setIsSaving(true)
      if (templateModal.editItem?.id) {
        const id = templateModal.editItem.id
        await upsertTemplate({ ...data, id })
        await queryClient.invalidateQueries({ queryKey: ['templates'] })
        showToast(
          'success',
          t(
            'library.template.toast.update.success',
            'Template updated successfully',
          ),
        )
        closeTemplateModal()
      } else {
        await upsertTemplate(data)
        await queryClient.invalidateQueries({ queryKey: ['templates'] })
        showToast(
          'success',
          t(
            'library.template.toast.create.success',
            'Template created successfully',
          ),
        )
        closeTemplateModal()
      }
    } catch (error: any) {
      if (error.response?.data?.errorCode === VALIDATION_ERROR) {
        return {
          message: error.response?.data?.errorDescription ?? '',
          details: error.response?.data?.details ?? {},
        }
      }
      console.error('Error handling template: ', error)
      const errorMessage =
        error.details?.message ||
        error.message ||
        t(
          'library.template.toast.operation.error',
          'Operation failed. Please try again.',
        )
      showToast('danger', errorMessage)
    } finally {
      setIsSaving(false)
    }
  }

  const handleEditTemplate = (template: Template) => {
    openTemplateModal(template)
  }

  const handleDeleteTemplate = async (id: string | undefined) => {
    if (id) {
      openDeleteModal(id, 'template')
    }
  }

  const handleConfirmDelete = async () => {
    if (deleteModal.itemToDelete?.id) {
      try {
        await deleteTemplate({ id: deleteModal.itemToDelete.id })
        const updatedTemplates = templates.filter(
          (template) => template.id !== deleteModal.itemToDelete?.id,
        )
        queryClient.setQueryData(['templates'], updatedTemplates)
        await queryClient.invalidateQueries({ queryKey: ['templates'] })

        showToast(
          'success',
          t(
            'library.template.toast.delete.success',
            'Template deleted successfully',
          ),
        )
        closeDeleteModal()
      } catch (error: any) {
        console.error('Error deleting template:', error)
        const errorMessage =
          error.details?.message ||
          error.message ||
          t('library.template.toast.delete.error', 'Failed to delete template')
        showToast('danger', errorMessage)
      }
    }
  }

  const onSearchQueryChange = useCallback((value: string) => {
    setSearchQuery(value)
  }, [])

  const filteredTemplates =
    templates?.filter?.(
      (template) =>
        template.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
        template.displayName.toLowerCase().includes(searchQuery.toLowerCase()),
    ) || []

  const groupTemplates = (templates: Template[]) => {
    const initialGroups = Object.keys(TYPE_TRANSLATIONS).reduce(
      (acc, type) => {
        acc[type as LibraryItemType] = []
        return acc
      },
      {} as Record<LibraryItemType, Template[]>,
    )

    return templates.reduce((acc, template) => {
      acc[template.type].push(template)
      return acc
    }, initialGroups)
  }

  const groupedTemplates = groupTemplates(filteredTemplates)

  return (
    <div className="content-library__content">
      <div className="content-library__content-header">
        <div className="search">
          <SearchInput onChange={onSearchQueryChange} value={searchQuery} />
        </div>
        <div className="add-button">
          <Button
            icon="plus"
            onClick={() => openTemplateModal()}
            variant="secondary"
          >
            {t('library.template.add-button', 'Add Template')}
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center mh-300px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="content-library__content-section">
          {Object.entries(groupedTemplates).map(([type, templates]) => (
            <div
              key={type}
              className="content-library__content-section__wrapper"
            >
              <div className="content-library__content-section__title">
                {t(TYPE_TRANSLATIONS[type as LibraryItemType])}
              </div>
              {templates.length > 0 ? (
                <div className="content-library__content-section__content">
                  {templates.map((template) => (
                    <TemplateCard
                      key={template.id}
                      template={template}
                      onEdit={handleEditTemplate}
                      onDelete={handleDeleteTemplate}
                    />
                  ))}
                </div>
              ) : (
                <p className="text-muted">
                  {t(
                    'library.type.empty-group',
                    'No {{type}} templates available',
                    { type: TYPE_TRANSLATIONS[type as LibraryItemType] },
                  )}
                </p>
              )}
            </div>
          ))}
        </div>
      )}

      <TemplateManagementModal
        show={templateModal.show}
        onHide={closeTemplateModal}
        template={templateModal.editItem as Template}
        onSubmit={handleSubmit}
        isManager={isManager}
      />

      <ConfirmationModal
        show={deleteModal.show && deleteModal.itemToDelete?.type === 'template'}
        onHide={closeDeleteModal}
        onConfirm={handleConfirmDelete}
        message={t(
          'library.template.confirm-delete',
          'Are you sure you want to delete this template?',
        )}
      />
    </div>
  )
}

export default TemplateManagement
