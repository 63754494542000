import useStore, { Page } from '../components/features/Consult/store'
import { QRCodeModel } from '../components/features/InteractionSummary/QRCodeModel'
import useSummaryStore from '../components/features/InteractionSummary/store'
import { PATIENT_PROTOCOL } from '../utils/constants'
import { useAuth } from './useAuth'

const useQrCodeData = () => {
  const { user } = useAuth()
  const editedSummaries = useStore((state) => state.editedSummaries)
  const sharedAttachments = useStore((state) => state.sharedAttachments)
  const title = useSummaryStore((state) => state.title)
  const description = useSummaryStore((state) => state.description)
  const descriptionNonEmpty = useSummaryStore(
    (state) => state.descriptionNonEmpty,
  )
  const manualAttachments = useSummaryStore((state) => state.attachments)
  const summaryTitle = useSummaryStore((state) => state.summaryTitle)
  const summary = useSummaryStore((state) => state.summary)
  const transtcript = useStore((state) => state.transcript)
  const category = useSummaryStore((state) => state.category)
  const subCategory = useSummaryStore((state) => state.subCategory)
  const page = useStore((state) => state.page)

  let qrDataAvailable = false
  switch (page) {
    case Page.newManual:
      const qrTitle = !!summaryTitle?.trim()
        ? !!summaryTitle?.trim()
        : !!title?.trim()
      const qrDesc = !!summary?.trim()
        ? !!summary.trim()
        : !!description?.trim() && descriptionNonEmpty
      qrDataAvailable = qrTitle && qrDesc
      break
    default:
      qrDataAvailable =
        !!transtcript?.title?.trim() &&
        !!editedSummaries
          .find((s) => s.protocol_name === PATIENT_PROTOCOL)
          ?.content[0]?.content?.trim()
  }
  const getQrData = () => {
    const qrData: QRCodeModel = {
      v: '0.1',
      t: '',
      c: null,
      sc: null,
      hn: `${user?.profile?.firstName} ${user?.profile?.lastName}`,
      ht: user?.profile?.jobTitle ?? null,
      i: user?.organizationId?.toString() || user?.profile?.institution || '',
      s: '',
      u: [],
    }

    switch (page) {
      case Page.newManual:
        const qrTitle = !!summaryTitle ? summaryTitle : title
        const qrDesc = !!summary ? summary : description
        qrData.t = qrTitle ?? ''
        qrData.c = category?.value ?? null
        qrData.sc = subCategory?.value ?? null
        qrData.s = qrDesc ?? ''
        qrData.u = manualAttachments.map((a) => a.url)

        break
      default:
        qrData.t = transtcript.title
        qrData.s =
          editedSummaries.find((s) => s.protocol_name === PATIENT_PROTOCOL)
            ?.content[0]?.content ?? ''
        qrData.u = sharedAttachments.map((a) => a.url)
        break
    }
    console.log('qrData', qrData)
    return qrData
  }

  return { qrDataAvailable, getQrData }
}

export default useQrCodeData
